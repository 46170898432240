<template>
  <div class="profile">
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="form"
    >
      <seller-form
        @submit="handleSubmit(onSubmit)"
      >
        <fieldset class="requisites form-fields-group">
          <ul class="form-fields-list">
            <li class="form-fields-list__item">
              <x-form-item
                v-slot="validationData"
                label="ФИО"
                name="ФИО"
                rules="required"
              >
                <x-input
                  id="name"
                  v-model="form.user_name"
                  disabled
                  placeholder="Введите ФИО"
                  v-bind="validationData"
                />
              </x-form-item>
            </li>

            <li class="form-fields-list__item">
              <phone-field
                v-model="form.user_phone"
                disabled
              />
            </li>
          </ul>
        </fieldset>

        <div
          v-if="false"
          class="profile__actions"
        >
          <x-btn
            color="gray"
            @click="fillFieldsByUser"
          >
            Отменить
          </x-btn>

          <x-btn
            :loading="isLoading"
            type="submit"
          >
            Сохранить
          </x-btn>
        </div>
      </seller-form>
    </validation-observer>

    <ul class="profile__auth-actions">
      <h5 class="profile__auth-actions-heading">
        Настройки авторизации
      </h5>

      <li class="profile__auth-action">
        <x-link
          primary
          @click="onChangePasswordClick"
        >
          Изменить пароль
        </x-link>
      </li>

      <li class="profile__auth-action">
        <x-link
          primary
          @click="onAdd2faClick"
        >
          Настроить двухфакторную аутентификацию
        </x-link>
      </li>
    </ul>
  </div>
</template>

<script>
import SellerForm from '@/components/Interface/Form.vue'
import PhoneField from '@/components/Interface/PhoneField.vue'
import { redirectOnPassport } from '@/utils/system'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('profile')

export default {
  components: {
    SellerForm,
    PhoneField
  },

  data () {
    return {
      form: {
        user_name: '',
        user_phone: ''
      },
      isLoading: false
    }
  },

  computed: {
    ...mapState(['user'])
  },

  created () {
    this.fillFieldsByUser()
  },

  methods: {
    onInput ({ field, value }) {
      this.form[field] = value
    },

    fillFieldsByUser () {
      if (!this.user) return
      const { name, phone } = this.user
      this.form = {
        user_name: name,
        user_phone: phone
      }
    },

    async onSubmit () {
      try {
        this.isLoading = true

        // await this.updateClient(this.form)
      } finally {
        this.isLoading = false
      }
    },

    onAdd2faClick () {
      redirectOnPassport({ path: '/2fa', target: '_blank' })
    },

    onChangePasswordClick () {
      redirectOnPassport({
        path: '/reset',
        query: { email: this.user.email },
        target: '_blank'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .profile
    width 340px

    &__actions
      display flex
      align-items center
      justify-content flex-end
      margin-top 20px

    &__auth-actions
      margin-top 20px
      list-style none

    &__auth-action
      margin-top 10px

    &__auth-actions-heading
      font-size $font-sizes.headline-5
      font-weight $font-weights.semibold
</style>
